.o-camera-container{
    /* width: 100%; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#o-camera{
    max-width: 100%;
    max-height: 65%;
    border-radius: .5rem .5rem 0 0;
}

#o-camera-buttons-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
}