.o-logo-header{
    object-fit: contain;
}



@media (orientation: portrait) {
    .o-logo-header{
        max-width: 40%;
    }
  }