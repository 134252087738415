#o-assessment-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}



@media all and (orientation: portrait) { 
    #o-assessment-container{
        height: auto;
        /* justify-content:start; */
    }
}