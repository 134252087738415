body{
    width: 100vw;
    height: 100vh; 
    margin: 0;
    padding: 0;
}

.App {
    text-align: center;
    width: 100vw;
    height: 100vh; 
    margin: 0;
    padding: 0;
}
