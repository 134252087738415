#o-footer-container{
    bottom: 0;
    width: 100%;
    text-align: center;
}

#o-footer-logos{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

@media screen and (orientation: portrait) {
    #o-footer-logos{
        display: flex;
        margin-top: 2rem;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }
}